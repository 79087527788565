@import 'default';

$primaryColor: #eb302a !default;
$white: white !default;
$black: black !default;
$tcolor: #747474;

%container {
    max-width: 1000px;
    margin: {
        left: auto !important;
        right: auto !important;
    }
}


// grid
.g-content {
    padding: 50px 0 70px;
    @include media(420px) {
        padding: 30px 0 50px;
    }

    .g-content-container {
        @extend %container;
        @include media(420px) {
            padding: 0 15px;
        }
    }
}
.g-section {
    padding: 40px 0 !important;
}



// module
.m-content {
    .title {
        font-size: 26px;
        color: $primaryColor;
        text-decoration: underline;
        @include media(420px) {
            font-size: 18px;
        }

        &.f-clear-decoration {
            text-decoration: none;
        }
    }

    .note {
        padding: 20px 0 0;
        color: $black;
        font-size: 18px;
        line-height: 1.2;
        font-weight: bold;
        text-transform: uppercase;
        @include media(420px) {
            font-size: 14px;

            br {
                display: none;
            }
        }
    }

    .text {
        padding: 20px 0 0;
        text-align: justify;
        color: $tcolor;
        @include media(420px) {
            font-size: 14px;
        }
    }
}
.m-list {
    $l: 25px;
    
    margin: {
        bottom: 30px;
    }
    padding: {
        left: $l;
    }

    &.f-clear-adorn {
        padding: {
            left: 0;
        }
        
        .m-list-title {
            &:before {
                display: none;
            }
        }
    }
    &.f-inline-black {
        .m-list-item {
            display: inline-block;
            min-width: 260px;
        }
    }
    &.s-primary {
        .m-list-item {
            &:before {
                background: $primaryColor;
            }
        }
    }
    &.s-black {
        color: black;

        .m-list-item {
            &:before {
                background: black;
            }
        }
    }
    &.s-gray {
        color: $tcolor;

        .m-list-item {
            &:before {
                background-color: $tcolor;
            }
        }
    }

    .m-list-title {
        position: relative;
        line-height: 1.2;
        font-size: 20px;
        font-weight: bold;
        @include media(420px) {
            font-size: 18px;
        }

        &:before {
            content: '';
            position: absolute;
            left: -$l;
            top: 3.5px;
            border: {
                left: 10px solid $white;
                top: 10px solid transparent;
                bottom: 10px solid transparent;
            }
            @include media(420px) {
                top: 3px;
                border: {
                    width: 8px;
                }
            }
        }
    }

    .m-list-sub-title {
        margin: {
            top: 1em;
        }
        font-size: 18px;
        @include media(420px) {
            font-size: 16px;
        }
    }

    .m-list-text {
        margin: {
            top: 1em;
        }
        text-align: justify;
        line-height: 1.2;
        font-size: 16px;
    }

    .m-list-item {
        margin: {
            top: 10px;
        }
        font-weight: bold;
        line-height: 1;
        @include media(420px) {
            font-size: 14px;
        }

        &:before {
            content: '';
            display: inline-block;
            width: 5px;
            height: 2px;
            margin: {
                right: 5px;
            }
            background-color: $white;
            vertical-align: middle;
        }
    }
}
.m-list-2 {
    .m-list-title {
        position: relative;
        font-size: 20px;
        font-weight: normal;
        @include media(420px) {
            font-size: 18px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -25px;
            transform: translateY(-50%);
            border: {
                left: 10px solid $primaryColor;
                top: 10px solid transparent;
                bottom: 10px solid transparent;
            }
            @include media(420px) {
                border: {
                    width: 6px;
                }
            }
        }
    }

    .m-list-sub-title {
        font-size: 18px;
        @include media(420px) {
            font-size: 16px;
        }
    }

    .m-list-detail {
        font-weight: bold;
        font-size: 16px;
        @include media(420px) {
            font-size: 14px;
        }
    }
}
.m-list-3 {
    display: flex;
    flex-wrap: wrap;

    .wpb_column {
        padding: {
            left: 15px;
            right: 15px;
        }
        
        > .vc_column-inner {
            padding: {
                left: 0;
                right: 0;
            }
        }

        &.vc_col-has-fill {
            > .vc_column-inner {
                padding: 10px 15px 20px;
            }
        }
    }

    @include media(420px) {
        &.f-t {
            .vc_col-has-fill {
                margin: {
                    top: 30px;
                }
            }
        }

        &.f-b {
            .vc_col-has-fill {
                margin: {
                    bottom: 30px;
                }
            }
        }
    }
    
    > .vc_col-has-fill {
        @include media(420px) {
            padding: {
                left: 15px;
                right: 15px;
            }
        }

        > .vc_column-inner {
            height: 100%;
            @include media(420px) {
                padding: {
                    bottom: 20px;
                }
            }
        }
    }
}
.m-address {
    $l: 30px;
    
    list-style: none;
    padding: {
        left: $l;
    }

    .m-address-item {
        position: relative;
        line-height: 1.2;
        font-size: 16px;
        @include media(420px) {
            font-size: 14px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -$l;
            width: 20px;
            height: 20px;
            background: url('../images/sprites.png') center/100% auto no-repeat;
        }
        
        + .m-address-item {
            margin: {
                top: 10px;
            }
        }

        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                &:before {

                    @if $i == 1 {
                        background-position: center top;
    
                    } @else if $i == 2 {
                        background-position: center -29px;

                    } @else if $i == 3 {
                        background-position: center -57px;

                    } @else if $i == 4 {
                        background-position: center -83px;
                    } 
                }
            }
        }
    }
}
.m-text {
    .m-text-title {
        font-size: 26px;
        color: $primaryColor;
        @include media(420px) {
            font-size: 18px;
        }
    }

    .m-text-title-2 {
        font-size: 20px;
        color: white;
        @include media(420px) {
            font-size: 16px;
        }
    }

    .m-text-content {
        margin: {
            top: 0.6em;
        }
        color: white;
        font-size: 18px;
        line-height: 1.4;
        @include media(420px) {
            font-size: 14px;
        }
    }
}
.m-banner {
    position: relative;
    height: 330px;
    @include media(1024px) {
        height: 300px;
    }
    @include media(800px) {
        height: 200px;
    }
    @include media(420px) {
        height: 126px;
    }
    @include media(359px) {
        height: 104px;
    }

    .m-banner-title {
        position: absolute;
        left: 50%;
        bottom: 10%;
        margin: {
            left: -580px !important;
        }
        @include media(1024px) {
            margin: {
                left: -480px !important;
            }
        }
        @include media(800px) {
            margin: {
                left: -380px !important;
            }
        }
        @include media(420px) {
            margin: {
                left: 0 !important;
            }
            transform: translateX(-50%);
            width: 100%;
        }

        .vc_custom_heading {
            text-transform: uppercase;
            font-size: 40px;
            @include media(800px) {
                font-size: 30px;
            }
            @include media(420px) {
                font-size: 20px;
            }

            &:before {
                content: '';
                display: inline-block;
                margin: {
                    right: 1em;
                }
                border: {
                    left: .5em solid $primaryColor;
                    top: .5em solid transparent;
                    bottom: .5em solid transparent;
                }
                vertical-align: top;
            }
        }
    }
}



// unit
.u-btn {
    margin: {
        bottom: 0;
    }

    .vc_btn3 {
        padding: {
            top: 10px;
            bottom: 10px;
            right: 40px;
        }
        background-color: $primaryColor !important;
        background-image: none;
        border: none !important;
        font-size: 16px !important;
        color: $white !important;
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
    }

    .vc_btn3-icon {
        left: 30px !important;
        font-size: 20px !important;
    }
}
.u-title {
    .u-title-text {
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        font-size: 30px;

        &:before {
            content: '';
            position: absolute;
            left: -40px;
            top: 50%;
            transform: translateY(-50%);
            border: {
                left: 12px solid $primaryColor;
                top: 12px solid transparent;
                bottom: 12px solid transparent;
            }
        }
    }
}





// function
.f-weight-n {
    font-weight: normal !important;
}
.f-weight-b {
    font-weight: bold;
}
.f-underline {
    text-decoration: underline;
}
.f-clear-pseudo {
    &:after,
    &:before {
        @extend %none;
    }
}
.f-container {
    @extend %container;
}
.f-flex {
    display: flex;
    flex-wrap: wrap;
}
.f-height {
    > .vc_column-inner {
        height: 100%;
    }
}
@for $i from 1 through 5 {

    .f-mt-#{$i} {
        margin: {
            top: $i * 10px;
        }
    }
}
.f-uppercase {
    text-transform: uppercase;
}


// skin
.s-primary {
    color: $primaryColor !important;
}
.s-gray {
    color: $tcolor;
}
.s-white {
    color: white !important;
}



.header-clone {
    @extend %none;
}
.site-header {
    position: absolute;
    top: 10px !important;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    max-width: 1200px;
    background-color: $white;
    @include media(420px) {
        position: static;
        transform: translateX(0);
    }

    &.float-header {
        top: 10px;
        padding: 0;
    }

    $h: 40px;
    .site-logo {
        display: block;
        margin: {
            top: $h / 2;
            bottom: $h / 2;
        }
        @include media(420px) {
            margin: 10px auto;
        }
    }

    .mainnav {
        position: relative;
        padding: {
            top: 24px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -$h + 26px;
            left: 25px;
            width: 45px;
            height: 5px;
            background-color: $primaryColor;
            transition: left .3s cubic-bezier(1, 1, 1, 1),
            width .3s cubic-bezier(1, 1, 1, 1);
            @include media(1024px) {
                display: none;
            }
        }

        .menu-item {
            padding: 0 25px !important;
            text-transform: capitalize;
        }

    }

    .sub-menu {
        left: 50% !important;
        transform: translateX(-50%);
        margin: {
            top: 20px !important;
        }
        padding: {
            top: 0 !important;
        }
        border: 1px solid $primaryColor;
        @include media(420px) {
            border: {
                color: transparent;
            }
        }

        &:after {
            @extend %none;
        }

        .menu-item-has-children {
            > a {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    border: {
                        left: 4px solid $primaryColor;
                        top: 4px solid transparent;
                        bottom: 4px solid transparent;
                    }
                    transform: translateY(-50%);
                    @include media(420px) {
                        display: none;
                    }
                }
            }

            .sub-menu {
                left: 150% !important;
                margin: {
                    top: -1px !important;
                    left: 1px !important;
                }
                @include media(420px) {
                    left: 50% !important;
                    padding: {
                        left: 10px !important;
                    }
                }
            }
        }

        .menu-item {
            width: 100% !important;
            padding: 0 !important;

            + .menu-item {
                border: {
                    top: 1px solid rgba($primaryColor, .4);
                }
            }
        }

        a {
            width: 100% !important;
            padding: {
                top: 4px !important;
                bottom: 4px !important;
                right: 0 !important;
            }
            background-color: rgba($white, .9) !important;
            border: none !important;
            font-size: 14px !important;
            color: $black !important;
            @include media(420px) {
                padding: {
                    top: 10px !important;
                    bottom: 10px !important;
                }
                background-color: rgb(0, 0, 0) !important;
                color: $white !important;
            }
            
            &:hover {
                background-color: rgba($primaryColor, .9) !important;
            }
        }
    }

    @include media(1024px) {
        .btn-menu {
            margin: {
                top: 18px;
            }
            color: $primaryColor;
            @include media(420px) {
                margin: {
                    top: 0;
                }
                line-height: 1;
            }

            &.active {
                line-height: 1;
            }
        }
    }
}
.footer-widgets {
    padding: 40px 0;

    &.s-bg-gray {
        background-color: rgb(244, 244, 244) !important;
    }

    .widget {
        &:last-child {
            margin: {
                bottom: 0;
            }
        }
    }

    .widget-title {
        padding: {
            bottom: 10px;
        }
        font-size: 20px;
        color: $primaryColor;
    }

    .menu {
        .menu-item {
            position: relative;
            padding: {
                left: 20px;
            }
            text-transform: capitalize;
            line-height: 1;

            &:first-child {
                padding: {
                    top: 5px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 6px;
                height: 6px;
                border: {
                    right: 1px solid $black;
                    bottom: 1px solid $black;
                }
                transform: rotate(-45deg) translateY(-50%);
            }

            .sub-menu {
                @extend %none;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    #text-3 {
        padding: {
            top: 2.4em;
            left: 16%;
        }
        @include media(800px) {
            padding: {
                left: 0;
            }
        }

        strong {
            &:first-child {
                margin: {
                    left: 0.4em;
                }
            }

            &:nth-child(3) {
                margin: {
                    left: 3.6em;
                }
            }
        }
    }

    #nav_menu-4 {
        padding: {
            top: 0;
        }
    }
}
.site-footer {
    text-align: center;
    line-height: 1;
    font-size: 12px;
}


// home
.h-we {
    padding: 36px 0;

    > .vc_row-fluid {
        @extend %container;
    }

    .vc_col-sm-4 {
        @include media(420px) {
            + .vc_col-sm-4 {
                margin: {
                    top: 14px;
                }
            }
        }
        
        > .vc_column-inner {
            padding: {
                left: (14px / 2);
                right: (14px / 2);
            }
        }
    }

    .img-wrap {
        position: relative;

        &:hover {
            .title {
                top: 30%;
                @include opacity(0);
                transition: top .3s cubic-bezier(.5, .5, 0, 0),
                opacity .3s cubic-bezier(1, 1, 1, 1);
            }
        }
        
        .title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 90%;
            text-transform: uppercase;
            font-size: 24px;
        }
    }

    .vc_btn3-container {
        margin: {
            top: 14px;
        }

        .vc_btn3 {
            &:hover {
                background-color: transparent !important;
                color: $primaryColor !important;
            }
        }
    }
}
.h-qoute {
    height: 120px;
    padding: {
        top: 0 !important;
    }
    @include media(1024px) {
        height: 100px;
    }

    .container {
        @extend %container;
        padding: {
            left: 0;
            right: 0;
        }
    }

    .vc_row,
    .wpb_column,
    .vc_column-inner,
    .wpb_wrapper {
        height: 100%;
    }

    .title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-transform: uppercase;
        font-size: 32px;
        @include media(420px) {
            font-size: 24px;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -40px;
            transform: translateY(-50%);
            border: {
                left: 14px solid $white;
                top: 14px solid transparent;
                bottom: 14px solid transparent;
            }
            @include media(420px) {
                right: -20px;
                border: {
                    width: 8px;
                }
            }
        }
    }
}
.h-wedo {
    padding: 60px 0 40px !important;
    color: $white;
    @include media(420px) {
        padding: {
            top: 40px !important;
        }
    }

    .text {
        margin: {
            top: 80px;
        }
        @include media(420px) {
            margin: {
                top: 20px;
            }
        }
        
        em {
            text-decoration: underline;
            font-style: normal;
        }

        text-align: justify;
    }

    .vc_btn3-container {
        margin: {
            top: 20px;            
        }

        .vc_btn3 {
            &:hover {
                background-color: transparent !important;
            }
        }
    }
}


// about 
.a-list {

    .container {
        @extend %container;
    }

    .item {
        padding: {
            bottom: 35px;
        }

        
    }

    @each $i in l, r {
        .item-#{$i} {
            @if $i == l {
                .wpb_text_column {
                    padding: {
                        right: 40px;
                    }
                    @include media(420px) {
                        padding: {
                            right: 0;
                            bottom: 30px;
                        }
                    }
                }

            } @else {
                .wpb_text_column {
                    padding: {
                        left: 40px;
                    }
                    @include media(420px) {
                        padding: {
                            left: 0;
                            top: 30px;
                        }
                    }
                }
            }
        }
    }
}
@include media(420px) {
    .a-quality {
        .wpb_single_image {
            margin: {
                top: 20px;
            }
        }
    }
}
.a-equipment {
    padding: {
        bottom: 35px;
    }

    .container {
        @extend %container;
    }

    .title {
        font-size: 28px;
        @include media(420px) {
            font-size: 20px;
        }
    }

    .list {
        padding: {
            left: 0;
        }
        margin: {
            top: 15px;
        }
        list-style: none;

        .item {
            position: relative;
            padding: {
                left: 10px;
            }
            line-height: 1;
            @include media(420px) {
                font-size: 14px;
            }

            + .item {
                margin: {
                    top: 10px;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 6px;
                left: 0;
                width: 4px;
                height: 1px;
                background-color: $white;
            }
        }
    }
}
.a-difference {
    padding: {
        bottom: 35px;
    }

    .container {
        @extend %container;
    }

    .vc_custom_heading {
        font-size: 28px;
        @include media(420px) {
            font-size: 20px;
        }
    }

    .wpb_text_column {
        margin: {
            top: 10px;
        }
        @include media(420px) {
            font-size: 14px;
        }
    }
}
.a-work {
    padding: 60px 0 !important;

    .container {
        @extend %container;
    }

    .vc_col-sm-6 {
        padding: 0 20px;

        @include media(420px) {
            + .vc_col-sm-6 {
                margin: {
                    top: 20px;
                }
            }
        }
    }

    .item {
        position: relative;

        .title {
            position: absolute;
            bottom: 15px;
            left: 15px;
            padding: 10px 50px 10px 30px;
            background-color: rgba(235, 48, 42, .8);
            font-size: 18px;
            font-weight: normal;
            clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
            @include media(400px) {
                left: 25px;
                padding: {
                    left: 20px;
                    right: 30px;
                }
                font-size: 14px;
            }
        }
    }
}


// milling
.ma-efficient {
    background: url('../images/ask-bg.png') right bottom/250px auto no-repeat;

    .container {
        @extend %container;
    }

    .img-wrap {
        padding: {
            bottom: 140px;
        }
        @include media(420px) {
            padding: {
                bottom: 35px;
            }
        }

        .wpb_single_image {
            border: 4px solid $primaryColor;
            @include media(420px) {
                border: none;
                
                img {
                    border: 4px solid $primaryColor;
                }
            }
        }
        
        .vc_col-sm-4 {
            padding: 0 7px;
            @include media(420px) {
                + .vc_col-sm-4 {
                    margin: {
                        top: 20px;
                    }
                }          
            }
        }
    }

    .list-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: {
            top: -100px;
        }
        @include media(420px) {
            margin: {
                top: 35px;
            }
        }

        .vc_column-inner {
            height: 100%;
        }

        .vc_col-sm-6 {
            width: calc(100% / 2 - 14px);
            margin: {
                left: 7px;
                right: 7px;
            }
            @include media(420px) {
                width: 100%;
                
                + .vc_col-sm-6 {
                    margin: {
                        top: 20px;
                    }
                }
            }
        }

        .wpb_text_column {
            padding: {
                bottom: 50px;
            }
        }
    }

    .questions-wrap {
        margin: {
            top: 40px;
        }
        padding: {
            bottom: 80px;
        }

        @include media(420px) {
            .wpb_column + .wpb_column {
                margin: {
                    top: 20px;
                }
            }
        }

        .vc_custom_heading {
            padding: {
                bottom: 30px;
            }
            text-decoration: underline;
            font-size: 28px !important;
            @include media(420px) {
                font-size: 18px !important;

                br {
                    display: none;
                }
            }
        }

        .list {
            padding: {
                left: 0;
            }
            color: $black;
            list-style: none;
            @include media(420px) {
                font-size: 14px;
            }

            &.s-primary {
                font-weight: bold;
            }

            .item + .item {
                margin: {
                    top: 25px;
                }
                @include media(420px) {
                    margin: {
                        top: 10px;
                    }
                }
            }
        }
    }
}
.cnc-list {
    @include media(420px) {
        .vc_col-has-fill {
            margin: {
                top: 30px;
            }
            padding: {
                left: 15px;
                right: 15px;
            }

            > .vc_column-inner {
                padding: {
                    bottom: 50px;
                }
            }
        }
    }
}
.precision-img,
.grinding-img,
.turning-img {
    border-radius: 3px;
    overflow: hidden;
    @include media(420px) {
        margin: {
            top: 30px;
        }
    }
}

// facilities list
.fa-list {
    + .fa-list {
        margin: {
            top: 60px;
        }
    }
    
    @include media(420px) {
        .vc_col-sm-6 + .vc_col-sm-6 {
            margin: {
                top: 30px;
            }
        }
    }
    
    .title {
        font-size: 28px;
        text-decoration: underline;
        @include media(420px) {
            font-size: 18px;
        }
    }

    .wpb_single_image {
        margin: 20px 0;
    }

    .wpb_text_column {
        margin: {
            top: 20px;
        }
        padding: {
            left: 10px;
        }
        color: $black;
        @include media(420px) {
            font-size: 14px;
        }

        p + P {
            margin: {
                top: 30px;
            }
            @include media(420px) {
                margin: {
                    top: 10px;
                }
            }
        }
    }
}

// contact
.c-contact {
    .title {
        font-size: 28px;
        text-decoration: underline;

        @include media(420px) {
            font-size: 18px;
        }
    }

    .wrap {
        color: $black;

        .list {
            padding: {
                left: 80px;
                bottom: 30px;
            }
            @include media(420px) {
                padding: {
                    left: 30px;
                }
            }
        }

        .wpb_text_column {
            margin: {
                top: 30px;
            }
        }

        .wpb_single_image {
            width: 80%;
            @include media(800px) {
                width: 100%;
            }
        }
    }
    @include media(420px) {
        .vc_col-sm-6 + .vc_col-sm-6 {
            margin: {
                top: 30px;
            }
        }
    }
}
.c-rfq {
    > .vc_custom_1532422286529 {
        background-position: left bottom !important;
        background-size: 60% auto !important;
    }

    .text {
        text-align: left;
        color: $black;

        p + p {
            margin: {
                top: 20px;
            }
        }

        strong {
            font-weight: normal;
            color: $primaryColor;
        }

        a {
            &:hover {
                color: $primaryColor;
                text-decoration: underline;
            }
        }
    }

    .wpcf7 {
        padding: 30px;
        background-color: rgb(240, 240, 240);

        br {
            display: none;
        }

        %input {
            width: 100%;
            background-color: rgb(255, 255, 255);
            border: none;
            color: $black;
            @include media(420px) {
                font-size: 14px;
            }

            &:focus {
                outline: 0;
            }

            &::placeholder {
                color: $black;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $black;
            }
            &::-ms-input-placeholder {
                color: $black;
            }
        }

        input {
            &[type="text"],
            &[type="tel"],
            &[type="email"] {
                @extend %input;
                height: 43px;
            }

            &[type="file"] {
                width: 220px;
                margin: {
                    left: auto;
                }
                color: $black;
            }
        }

        button[type="submit"] {
            padding: 5px 50px 5px 20px !important;
            margin: {
                top: 6px;
            }
            border: none;
            border-radius: 0;
            font: 16px Arial;
            clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);

            &:before {
                content: '';
                display: inline-block;
                margin: {
                    right: 10px;
                }
                border: {
                    left: 6px solid rgb(255, 255, 255);
                    top: 6px solid transparent;
                    bottom: 6px solid transparent;
                }
            }

            &:hover {
                &:before {
                    border: {
                        left-color: $primaryColor;
                    }
                }
            }
        }

        textarea {
            @extend %input;
            resize: none;
        }

        .your-name,
        .tel-107,
        .your-email,
        .file-903,
        .your-message {
            display: block;
            margin: {
                top: 12px;
            }
        }

        .file-903 {
            position: relative;
            height: 43px;
            padding: {
                top: 10px;
            }
            background-color: $white;
            text-align: right;
            @include media(420px) {
                font-size: 14px;
            }

            &:before {
                content: '*Upload File';
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: $black;
            }
        }
    }
}


// automation
.automation-img {
    margin: {
        top: 30px;
    }
}