@mixin media($width) {
    @media screen and (max-width: #{$width}) {
        @content;
    }
}

@mixin opacity($opacity){
    opacity: $opacity;
    $opacity-id: $opacity * 100;
    filter:Alpha(opacity = $opacity-id); //IE8
}

@mixin font($name, $fileName) {
    @font-face {
        font-family: $name;
        src: url('../fonts/#{$fileName}.eot'); /* IE9+ */
        src: url('../fonts/#{$fileName}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/#{$fileName}.woff') format('woff'), /* chrome、firefox */
            url('../fonts/#{$fileName}.woff2') format('woff2'), 
            url('../fonts/#{$fileName}.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url('../fonts/#{$fileName}.svg#fontname') format('svg'); /* iOS 4.1- */
    }
}

%none {
    display: none;
    visibility: hidden;
    @include opacity(0);
    font-size: 0;
}

%oneRow {
    width: 100%;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

body,
html {
    position: relative;
    margin-top: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
}
h1,h2,h3,
h4, h5, h6,
p {
    margin: 0;
}

#wpadminbar,
.page .entry-header,
.entry-footer {
    display: none !important;
}
.content-area .hentry,
.page-wrap {
    padding: {
        top: 0 !important;
        bottom: 0 !important;
    }
}
.admin-bar .site-header.float-header,
.admin-bar .site-header {
    top: 0;
}
.page .page-wrap .content-wrapper, 
.single .page-wrap .content-wrapper {
    padding: 0;
}
.page-wrap .content-wrapper {
    padding: {
        top: 0;
        bottom: 0;
    }
}
.wpb_content_element {
    margin: {
        bottom: 0 !important;
    }
}
.vc_row-no-padding .vc_inner {
    margin: {
        left: auto !important;
        right: auto !important;
    }
}
.wpb_gmaps_widget .wpb_wrapper {
    padding: 0 !important;
}
.vc_section {
    margin: {
        left: 0;
        right: 0;
    }
}
.container {
    margin: {
        left: auto !important;
        right: auto !important;
    }
}